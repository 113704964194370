<template>
    
  <div class="">
    <div class="midArea" ref="midArea">
      <div class="title_1">简单的工具</div>
      <div class="title_2">其实不简单</div>
      <div class="picsArea">
        <div class="pic pic_1"></div>
        <div class="pic pic_2"></div>
        <div class="pic pic_3"></div>
        <div class="pic pic_4"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MidArea',
  data() {
    return {}
  },
  components: {},
  created() {},
  mounted() {},
  methods: {}
}
</script>

<style scoped>
.midArea {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 500px;
  background-color: rgba(11, 190, 137, 0.712);
}
.title_1 {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translate(-50%);
  font-size: 20px;
}
.title_2 {
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translate(-50%);
  font-size: 15px;
}
.picsArea {
  position: absolute;
  top: 100px;
  left: 50%;
  transform: translate(-50%);
  width: 1100px;
  height: 300px;
  background-color: rgba(117, 42, 42, 0.692);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.pic {
  width: 230px;
  height: 230px;
  border-radius: 20px;
  overflow: hidden;
  background-color: rgba(211, 208, 37, 0.911);
}
</style>
